<template>
  <ContentSection :style="`--range-steps: ${data.images.length};`">
    <Container indent="base">
      <h2 class="mb-6 text-2xl font-medium uppercase text-gold-300 md:text-4xl">
        {{ data.heading }}
      </h2>
    </Container>
    <div
      ref="scroller"
      aria-label="Gallery Scroller"
      class="hide-scrollbar flex h-auto gap-6 overflow-x-auto overflow-y-hidden"
      @scroll="onScroll($event)"
    >
      <div
        v-for="(image, i) in data.images"
        :key="image.id"
        v-motion="{
          initial: {
            y: 100,
            opacity: 0,
          },
          visibleOnce: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 50 * i,
              duration: 800,
              type: 'keyframes',
              ease: [0, 0, 0.16, 0.97],
            },
          },
        }"
        class="aspect-[1.3] w-[80vw] max-w-[80svh] shrink-0 first-of-type:ml-container-mobile-sm last-of-type:mr-container-mobile-sm first-of-type:md:ml-container-desktop-base last-of-type:md:mr-container-desktop-base"
      >
        <AppImage
          :src="image"
          class="h-full object-cover pb-4"
          :style="`object-position: ${image?.focus_css};`"
        />
      </div>
    </div>
    <Container
      indent="sm"
      class="mt-4 flex w-full items-center justify-end gap-4"
    >
      <input
        id="scrollProgress"
        ref="scrollProgress"
        type="range"
        min="0"
        max="100"
        :value="progress"
        class="inputSlider w-full sm:w-1/2 lg:w-1/4"
        @input="(event) => (progress = event?.target?.value)"
      />

      <label
        for="scrollProgress"
        class="min-w-fit text-sm font-medium text-gold-300"
        aria-label="Gallery Scroller Progress"
        >{{ Math.round((progress / 100) * (data.images.length - 1)) + 1 }} von
        {{ data.images.length }}</label
      >
    </Container>
  </ContentSection>
</template>

¨
<script setup lang="ts">
import type { ReplicatorComponentsGalleryFragment } from '#gql'
import type { Enforce } from '~/types/general'

defineProps<{
  data: Enforce<ReplicatorComponentsGalleryFragment>
}>()

const scroller = ref<HTMLElement | null>(null)
const scrollProgress = ref<HTMLElement | null>(null)

const progress = ref(0)

//compute progress
function onScroll(event: Event) {
  let element = event.target as HTMLElement
  progress.value =
    (element?.scrollLeft / (element?.scrollWidth - element?.clientWidth)) * 100
}

// watch progress on change and scroll to the right position
watch(progress, (value) => {
  scroller.value!.scrollLeft =
    (scroller.value!.scrollWidth - scroller.value!.clientWidth) * (value / 100)
})
</script>

<style scoped>
.inputSlider {
  -webkit-appearance: none;
  outline: none;
  -webkit-transition: 0.2s;
  @apply bg-gold-200 h-0.5;
}

.inputSlider:hover {
  opacity: 1;
}

.inputSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: calc(100% / var(--range-steps));
  @apply h-4 bg-gold-500;
  cursor: pointer;
  /* expand hitbox */
  @apply border-y-[7px] border-white border-x-0 border-solid;
}

.inputSlider::-moz-range-thumb {
  appearance: none;
  width: calc(100% / var(--range-steps));
  @apply h-0.5 bg-gold-500;
  cursor: pointer;
}
</style>
